import { useLocation, useMatches } from "@remix-run/react";
import { browserTracingIntegration, init } from "@sentry/remix";
import { useEffect } from "react";

import { version } from "../../package.json";

export function initSentry() {
  if (import.meta.env.REACT_APP_SENTRY_DSN) {
    init({
      dsn: import.meta.env.REACT_APP_SENTRY_DSN,
      release: `workbench@${version}`,
      environment: "production",
      integrations: [browserTracingIntegration({ useEffect, useLocation, useMatches })],
      tracesSampleRate: parseFloat(import.meta.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE ?? "0.0"),
    });
  }
}
